import api from '@common/api';
import { getFormatDateRangeValues } from '@common/utils';

export const getExitOrderList = (data) => {
  const params = {
    convoy_enter_id: data.customer,
    car_number: data.driverNumber,
    in_stock_user_id: data.enterStaff,
    status: data.orderStatus,
    ship_ticket: data.ship_ticket,
    page: data.page,
    box_type: data.box_type,
    ship_id: data.ship_id,
    ship_enter_id: data.ship_enter_id,
    box_code: data.box_code,
    sx_send_status: data.sx_send_status,
  };
  if (data.wharf_id) {
    params.wharf_id = data.wharf_id[1];
  }
  if (data.box_size) {
    params.box_size = data.box_size;
  }
  if (data.position && data.position.length > 0) {
    params.area_id = data.position[0];
  }
  if (data.position && data.position.length > 1) {
    params.field_id = data.position[1];
  }
  if (data.position && data.position.length > 2) {
    params.row_id = data.position[2];
  }
  if (data.position && data.position.length > 3) {
    params.position_id = data.position[3];
  }
  if (data.dateType == 1) {
    const switchBillDate = getFormatDateRangeValues(
      data.switchBillDate,
      'YYYY-MM-DD HH:mm:ss',
    );
    params.create_time_begin = switchBillDate[0];
    params.create_time_end = switchBillDate[1];
  }
  if (data.dateType == 2) {
    const switchBillDate = getFormatDateRangeValues(
      data.switchBillDate,
      'YYYY-MM-DD HH:mm:ss',
    );
    params.real_in_time_begin = switchBillDate[0];
    params.real_in_time_end = switchBillDate[1];
  }
  // if (data.shipType == 1) {
  //   params.ship_id = data.ship;
  // }
  // if (data.shipType == 2) {
  //   params.ship_enter_id = data.ship;
  // }
  // if (data.orderType == 1) {
  //   params.box_code = data.orderNumber;
  // }
  if (data.orderType == 2) {
    params.tidan_code = data.orderNumber;
  }
  if (data.orderType == 3) {
    params.order_code = data.orderNumber;
  }
  return api.get('/stock/out/order', {
    params,
  });
};

export const getExitOrderActionList = (id, ActionType) => {
  if (ActionType == 1) {
    return api.put(`/stock/out/order/ti/${id}`);
  }
  if (ActionType == 2) {
    return api.put(`/stock/out/order/in_stock/${id}`);
  }
};

export const getExitOrderDetails = (id) => {
  return api.get(`/stock/out/order/${id}`);
};

export const getEntryOrderList = (data) => {
  const params = {
    convoy_enter_id: data.customer,
    car_number: data.driverNumber,
    in_stock_user_id: data.enterStaff,
    status: data.orderStatus,
    ship_ticket: data.ship_ticket,
    page: data.page,
    box_type: data.box_type,
    ship_id: data.ship_id,
    ship_enter_id: data.ship_enter_id,
    box_code: data.box_code,
    sx_send_status: data.sx_send_status,
    stock_in_purpose: data.stock_in_purpose,
  };
  if (data.wharf_id) {
    params.wharf_id = data.wharf_id[1];
  }
  if (data.box_size) {
    params.box_size = data.box_size;
  }
  if (data.position && data.position.length > 0) {
    params.area_id = data.position[0];
  }
  if (data.position && data.position.length > 1) {
    params.field_id = data.position[1];
  }
  if (data.position && data.position.length > 2) {
    params.row_id = data.position[2];
  }
  if (data.position && data.position.length > 3) {
    params.position_id = data.position[3];
  }
  if (data.dateType == 1) {
    const switchBillDate = getFormatDateRangeValues(
      data.switchBillDate,
      'YYYY-MM-DD HH:mm:ss',
    );
    params.create_time_begin = switchBillDate[0];
    params.create_time_end = switchBillDate[1];
  }
  if (data.dateType == 2) {
    const switchBillDate = getFormatDateRangeValues(
      data.switchBillDate,
      'YYYY-MM-DD HH:mm:ss',
    );
    params.real_in_time_begin = switchBillDate[0];
    params.real_in_time_end = switchBillDate[1];
  }
  // if (data.shipType == 1) {
  //   params.ship_id = data.ship;
  // }
  // if (data.shipType == 2) {
  //   params.ship_enter_id = data.ship;
  // }
  // if (data.orderType == 1) {
  //   params.box_code = data.orderNumber;
  // }
  if (data.orderType == 2) {
    params.tidan_code = data.orderNumber;
  }
  if (data.orderType == 3) {
    params.order_code = data.orderNumber;
  }
  return api.get('/stock/in/order', {
    params,
  });
};

export const getEntryOrderActionList = (id) => {
  return api.put(`/stock/in/order/luo/${id}`);
};

export const getEntryOrderDetails = (id) => {
  return api.get(`/stock/in/order/${id}`);
};

export const getEntryOrderUpdate = (id, data) => {
  return api.put(`/stock/in/order/${id}`, {
    car_number: data[0].carNumber,
    position_id: data[0].positionId[3],
  });
};

export const getEntryLogistics = (id) => {
  return api.get(`/stock/in/order/timeline/${id}`);
};
export const getExitLogistics = (id) => {
  return api.get(`/stock/out/order/timeline/${id}`);
};

export const getEntryOrderActions = (id, carNumber, positionId) => {
  return api.put(`/stock/in/order/in_stock/${id}`, {
    car_number: carNumber,
    position_id: positionId[3],
  });
};
// 获取进出场订单箱型搜索配置
export const getBoxTypeConfig = () => {
  return api.get(`/stock/base/box_size_config`);
};

// 进场订单-闸报发送
export const orderSxSend = (params = []) => {
  return api.post(`/stock/in/order/sx_send`, params);
};
// 出场订单-闸报发送
export const orderSxSendToOut = (params = []) => {
  return api.post(`/stock/out/order/sx_send`, params);
};
// 小票下载
export const paperPreview = (params = []) => {
  return api.post(`/stock/entry/stub/PDFPreview`, params);
};

// 进场订单列表
export const enterOrderListApi = (params) => ({
  url: '/stock/in/order',
  params,
});
// 出场订单列表
export const exitOrderListApi = (params) => ({
  url: '/stock/out/order',
  params,
});

/**
 * 出场订单-取消出场
 * @param {*} ids 已选择的订单id数组
 */
export const StockOutOrderCancelApi = (ids) => ({
  url: '/stock/out/order/cancel',
  method: 'put',
  data: {
    ids,
  },
});
/**
 * 取消进场订单
 * @param {*} ids number []   非必须  集装箱ID号
 * @returns
 */
export const CancelInOrderContainerApi = (ids) => ({
  url: '/stock/in/order/doInOrderCancelation',
  method: 'post',
  data: {
    container_ids: ids,
  },
});
/**
 * 进场订单-闸报发送（EIR）
 * @param {*} ids
 */
export const InOrderContainerSxSendApi = (ids) => ({
  url: '/stock/in/order/sx_send',
  method: 'post',
  data: {
    ids,
  },
});
/**
 * 出场场订单-闸报发送（EIR）
 * @param {*} ids
 */
export const OutOrderContainerSxSendApi = (ids) => ({
  url: '/stock/out/order/sx_send',
  method: 'post',
  data: {
    ids,
  },
});
/**
 * 进场订单-小票下载
 * @param {Array<number|string>} order_id
 */
export const PaperPreviewApi = (order_id) => ({
  url: '/stock/entry/stub/PDFPreview',
  method: 'post',
  data: {
    order_id,
  },
});
/**
 * 出场订单-下载小票
 * @param {*} ids
 */
export const DownloadContainerTicketApi = (container_ids) => ({
  url: '/stock/distribute/containerReleaseStub',
  method: 'post',
  data: { container_ids },
});
/**
 * 进场订单-落箱
 * @param {number|string} id
 */
export const EntryOrderActionApi = (id) => ({
  url: `/stock/in/order/luo/${id}`,
  method: 'put',
});
/**
 * 进场订单列表-位置编辑
 * @param {number|string} id 订单ID
 */
export const EntryOrderEditPositionApi = (id, field_id, position_id = 0) => ({
  url: `/stock/in/order/position/${id}`,
  method: 'put',
  data: {
    position_id,
    field_id,
  },
});
/**
 * 根据箱子id-获取同船同航次
 * @param {number} boxId 箱子ID
 */
export const DistributeShipInfo = (boxId) => ({
  url: `/stock/distribute/ship_info/${boxId}`,
});
/**
 * 进场订单-进场
 * @param {car_number,position_id} data
 * @param {number} id
 */
export const OrderInStockApi = (id, data) => ({
  url: `/stock/in/order/in_stock/${id}`,
  method: 'put',
  data,
});

/**
 * 进场订单-查看物流
 * @param {number} id
 */
export const EntryLogisticsApi = (id) => ({
  url: `/stock/in/order/timeline/${id}`,
});
/**
 * 出场订单-查看物流
 * @param {number} id 订单ID
 */
export const ExitLogisticsApi = (id) => ({
  url: `/stock/out/order/timeline/${id}`,
});
/**
 * 出场订单-提箱
 * @param {number} id 订单ID
 */
export const ExitLadingApi = (id) => ({
  url: `/stock/out/order/ti/${id}`,
  method: 'put',
});
/**
 * 出场订单-进场
 * @param {number} id 订单ID
 */
export const ExitInOrderApi = (id) => ({
  url: `/stock/out/order/in_stock/${id}`,
  method: 'put',
});
/**
 * 出场订单-出场
 * @param {number} id 订单ID
 */
export const ExitOutOrderApi = (id) => ({
  url: `/stock/out/order/chu/${id}`,
  method: 'put',
});
/**
 * 出场订单-多次集港
 * @param {number} id 订单ID
 * @param {object} data
 */
export const ExitMutiCargocvgeApi = (id, data) => ({
  url: `/stock/out/order/muti_cargocvge/${id}`,
  method: 'put',
  data,
});
/**
 * 出场订单-改派
 * @param {object} data
 */
export const OutOrderChangeAssign = (data) => ({
  url: `/stock/out/order/change_assign`,
  method: 'put',
  data,
});
/**
 * 出场订单-添加-获取可创建出场订单的箱子
 * @param {number} out_purpose // 1待集港|2自提'
 * @param {string} s // 搜索
 */
export const OrderSearchCanOutBox = (out_purpose, s) => ({
  url: `/stock/out/order/search_can_out_box`,
  params: {
    out_purpose,
    s,
  },
});
/**
 * 出场订单-添加
 * @param {object} data 创建的数据
 */
export const OutOrderCreate = (data) => ({
  url: '/stock/out/order/create',
  method: 'post',
  data,
});

export const OrderEidtSaveApi = (data) => ({
  url: '/cargocvge/mage/order',
  method: 'put',
  data,
});

/**
 * 根据船名航次-获取同船同航次位置信息
 * https://yapi.56hello.cn/project/257/interface/api/2588
 */
export const getShipInfo = (params) => {
  return api.get('/stock/distribute/ship_info', {
    params,
  });
};

/**
 * 查询报价
 * https://yapi.56hello.cn/project/371/interface/api/2532
 */
export const getPrice = (params) => {
  return api.post('/cargocvge/price/huodong/quoted/price', params);
};

/**
 * 批量添加订单
 * https://yapi.56hello.cn/project/215/interface/api/2594
 */
export const batchRegister = (params) => {
  return api.post('/cargocvge/mage/order', params);
};
/**
 * 车牌号反查司机信息
 * https://yapi.56hello.cn/project/413/interface/api/3255
 */
export const getDriverInfo = (number) => {
  return api.get('/driver/car/number/driver', { params: { number } });
};

/**
 * 导出进场订单
 */
export const exportInOrder = (ids) => {
  return api.get('/stock/in/order/list_export', { params: { ids } });
};

/**
 * 出场订单-关港信息发送
 * @param {*} ids  订单ids,array
 * @returns
 */
export const sendGuanGang = (ids) =>
  api.post('/stock/out/order/guangang_send', {
    ids,
  });
