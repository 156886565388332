import api from '@common/api';

export const areaConfig = () => {
  return api.get('/stock/distribute/area_config');
};
export const areaEdit = (params) => {
  return api.put('/stock/distribute/area_config', params);
};
export const areaLock = (params) => {
  return api.get('/stock/distribute/area_lock_config', {
    params,
  });
};
export const lock = (params) => {
  return api.put('/stock/distribute/area_lock', params);
};
export const unlock = (params) => {
  return api.put('/stock/distribute/area_unlock', params);
};
export const getAllLevel = (params) => {
  return api.get('/stock/distribute/getAllLevel', {
    params,
  });
};
// 二维场图-选位-设置提交
export const setAreaConfig = (params) => {
  return api.put('/stock/distribute/area_lock_config', params);
};

export const orderYard = (params) => {
  return api.get('/cargocvge/order/yard', {
    params,
  });
};

export const orderYardList = (params) => {
  return api.get('/cargocvge/order/yard/list', {
    params,
  });
};

export const yardLock = (params) => {
  return api.put('/cargocvge/order/yard/lock', params);
};

export const areaShipList = (params) => {
  return api.get('/stock/distribute/area_ship_list', {
    params,
  });
};

// 小票下载
export const download = (params) => {
  return api.post('/stock/distribute/containerReleaseStub', params);
};
// 发送ELCP
export const sendElcp = (params) => {
  return api.post('/cargocvge/order/yard/eclp', params);
};
// 运抵
export const toDoLock = (params) => {
  return api.post('/cargocvge/order/yard/containertodock', params);
};
// 刷新预约
export const refreshOrder = (params) => {
  return api.post('/cargocvge/order/yard/refresh', params);
};

// 刷新NCL
export const refreshNCL = (params) => {
  return api.get('/cargocvge/pap/ncl-refresh', {
    params,
  });
};

/**
 * 修改箱备注
 * box_id: '箱子ID,body,true,int',
 * order_id: '订单ID,body,true,int',
 * remark: '箱备注,body,true,string'
 * @returns
 */
export const remarkSubmit = (params) => {
  return api.put('/cargocvge/order/yard/remark', params);
};

// 单个添加分票
export const addCargocvgeTicket = (data) => {
  return api.post('/cargocvge/box/replace', data);
};

// 批量添加分票
export const batchAddCargocvgeTicket = (data) => {
  return api.post('/cargocvge/box/batch/ticket', data);
};

// 取消分票
export const deleteTicket = (data) => {
  return api.post('/cargocvge/box/del/ticket', data);
};

// 获取已分票数据
export const getCargocvgeTicket = (params) => {
  return api.get('/cargocvge/box/ticket/list', { params });
};
