import api from '@common/api';

export const OrderExamineApi = (params) => ({
  url: '/stock/healthy/log',
  method: 'GET',
  params,
});

export const examinePass = (data) => ({
  url: '/stock/healthy/log/agree',
  method: 'PUT',
  data,
});

export const examineFail = (data) => ({
  url: '/stock/healthy/log/reject',
  method: 'PUT',
  data,
});
