import { RedirectToPassport } from 'xiangxin-tool';
import api, { setRedirectToPassport } from 'xiangxin-design/lib/http/axios';

setRedirectToPassport(RedirectToPassport);

export default api;

export * from './menu';
export * from './staff';
export * from './yard';
export * from './common';
export * from './order';
export * from './wharf';
export * from './map';
export * from './driver';
export * from './examine';
