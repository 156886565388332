import { get } from 'lodash';
import api from '@common/api';
import { PlatformUtils } from 'xiangxin-tool'

const initialState = {
  id: null,
  enterpriseName: null,
  mobile: null,
  name: null,
  scope: null,
  stock_scope: null,
  permission: {
    containerRelease: false,
    containerReleaseExport: false,
    containerReleaseImport: false,
    containerGateIn: false,
  },
};

export const user = {
  state: initialState,
  reducers: {
    setLoggedUser: (state, payload) => {
      state.id = payload.id;
      state.enterpriseName = payload.enterpriseName;
      state.mobile = payload.mobile;
      state.name = payload.name;
      state.scope = payload.scope;
      state.stock_scope = payload.stock_scope;
      state.permission = payload.permission;
      return state;
    },
  },
  effects: (dispatch) => ({
    async getLoggedUser() {
      return await api
        .get('/user/account/info')
        .then(({ data, error_code, error_msg }) => {
          localStorage.userInfo = JSON.stringify(data);
          if (error_code) return Promise.reject(error_msg);
          return data
        }).then((data) => {
          return PlatformUtils.checkSystem(data)
        }).then((data) => {
          const convoyUserPermission = data.convoyPermissionOfAttributes;
          dispatch.user.setLoggedUser({
            id: data.id,
            enterpriseName: data.enter_name,
            mobile: data.mobile,
            name: data.name,
            scope: data.scope,
            stock_scope: data.stock_scope,
            permission: {
              containerRelease: get(
                convoyUserPermission,
                'permissionFX',
                false,
              ),
              containerReleaseExport: get(
                convoyUserPermission,
                'permissionFXExport',
                false,
              ),
              containerReleaseImport: get(
                convoyUserPermission,
                'permissionFXImport',
                false,
              ),
              containerGateIn: get(convoyUserPermission, 'permissionJG', false),
            },
          });
          return Promise.resolve(data);
        })
    },
  }),
};
