import api from '@common/api';

export const orderExport = async (params) => {
  return api.post('/stock/in/order/export', params);
};

/**
 * @description 堆场司机管理-列表
 * @author ZhangYù
 * @date 10/06/2022
 * @export
 * @param {*} params
 * @return {*}
 */
export const fetchStockDriver = ( params ) => ({
  url: '/stock/driver',
  params,
})

// type params {
//   ids: string
// }

/**
 * @description 堆场司机管理-操作-加入黑名单
 * @author ZhangYù
 * @date 10/06/2022
 * @export
 * @param {params} params
 * @return {*}
 */
export function InBlackList (params) {
  return api.put( "/stock/driver/in_blacklist", params )
}


/**
 * @description 堆场司机管理-操作-移出黑名单
 * @author ZhangYù
 * @date 10/06/2022
 * @export
 * @param {params} params
 * @return {*}
 */
export function OutBlackList ( params ) {
  return api.put( "/stock/driver/out_blacklist", params )
}

/**
 * @description 根据箱子信息-获取可选堆场司机
 * @author ZhangYù
 * @date 10/06/2022
 * @export
 * @param {*} s
 * @return {*}
 */
export function fetchStockUseDriver(s) {
  return {
    url: '/stock/driver/use_driver',
    params: { s }
  }
}

/**
 * @description 获取司机详情
 * @author ZhangYù
 * @date 13/06/2022
 * @param {number} id
 * @export
 * @return {*}
 */
export function getStockDriver(id) {
  return {
    url: `/stock/driver/${id}`,
  }
}

/**
 * @description 添加司机
 * @author ZhangYù
 * @date 13/06/2022
 * @param {any} data
 * @export
 * @return {*}
 */
export function addStockDriver(data) {
  return {
    url: `/stock/driver`,
    method: 'POST',
    data
  }
}

/**
 * @description 编辑司机
 * @author ZhangYù
 * @date 13/06/2022
 * @param {any} data
 * @export
 * @return {*}
 */
export function editStockDriver( id, data ) {
  return {
    url: `/stock/driver/${id}`,
    method: 'PUT',
    data
  }
}


/**
 * @description 堆场司机类型管理-列表
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {*} params
 * @return {*}
 */
export const YardDriverCateApi = () => {
  return {
    url: '/stock/driver/cate',
    method: 'GET',
  };
};

/**
 * @description 堆场司机类型管理-创建
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {*} params
 * @return {*}
 */
export const YardDriverCateAddApi = (name) => {
  return {
    url: "/stock/driver/cate",
    method: 'POST',
    data: {
      name
    }
  }
}

/**
 * @description 堆场司机类型管理-编辑
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {*} params
 * @return {*}
 */
export const YardDriverCateEditApi = ( id, name ) => {
  return {
    url: `/stock/driver/cate/${id}`,
    method: 'PUT',
    data: {
      name,
      id
    }
  }
}

/**
 * @description 堆场司机类型管理-删除
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {*} id
 * @return {*}
 */
export const YardDriverCateDelApi = ( id ) => {
  return {
    url: `/stock/driver/cate/${id}`,
    method: 'DELETE',
    params: {
      id
    }
  }
}

/**
 * @description 堆场司机管理-操作-代集港出场-不可用
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {string[]} ids 记录ids,body,true,array
 * @return {*}
 */
export const BatchStockDriverDJGDisabledApi = (ids) => {
  return {
    url: "/stock/driver/djg_out_disable",
    method: "PUT",
    data: {
      ids
    }
  }
}

/**
 * @description 堆场司机管理-操作-代集港出场-可用
 * @author ZhangYù
 * @date 22/08/2022
 * @export
 * @param {string[]} ids 记录ids,body,true,array
 * @return {*}
 */
export const BatchStockDriverDJGEnabledApi = ( ids ) => {
  return {
    url: "/stock/driver/djg_out_enable",
    method: "PUT",
    data: {
      ids
    }
  }
}
